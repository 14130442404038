import React, { Component } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import * as api from "./api";

import {
    ManageProducts,
    Login,
    Cart,
    Products,
    Signup,
    NotFound,
    Product,
    Navbar,
    MobileView
} from "./components";

import Context from "./Context";

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            cart: {},
            products: [],
            searchTerm: null,
            width: window.innerWidth,
            pinCode: null
        };

        this.routerRef = React.createRef();
        this.login = this.login.bind(this);
        this.signup = this.signup.bind(this);
        this.logout = this.logout.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.removeFromCart = this.removeFromCart.bind(this);
        this.clearCart = this.clearCart.bind(this);
        this.otpHandler = this.otpHandler.bind(this);
        this.incrementItemCount = this.incrementItemCount.bind(this);
        this.decrementItemCount = this.decrementItemCount.bind(this);
        this.checkout = this.checkout.bind(this);
        this.search = this.search.bind(this);
        this.completeSigningUp = this.completeSigningUp.bind(this);
        this.setPinCode = this.setPinCode.bind(this);
    }

    async componentDidMount() {
        this.setState({ isMobile: this.state.width <= 768 });

        let user = localStorage.getItem("user");
        let cart = localStorage.getItem("cart");
        let pinCode = localStorage.getItem("pincode");

        user = user ? JSON.parse(user) : null;
        cart = cart ? JSON.parse(cart) : {};
        pinCode = pinCode || null;

        this.setState({ user, pinCode, cart });

        const { data: products } = await api.fetchProducts();

        this.setState({ products, allProducts: products });

        window.addEventListener("resize", () => {
            this.setWidth();
            this.setState({ isMobile: this.state.width <= 768 });
        });

        return () => {
            window.removeEventListener("resize", () => {
                this.setWidth();
                this.setState({ isMobile: this.state.width <= 768 });
            });
        };
    }

    setPinCode = (c) => {
        this.setState({ pinCode: c });
        localStorage.setItem("pincode", c);
    };

    async otpHandler(otp, email) {
        let isVerified = false;
        const { status } = await api.verifyOtp(otp, email);
        if (status === 200) isVerified = true;
        return isVerified;
    }

    async completeSigningUp(name, password, address, tel, email) {
        const { data, status } = await api.completeSigningUp({
            name,
            password,
            address,
            tel,
            email
        });
        return { data, status };
    }

    setWidth = () => this.setState({ width: window.innerWidth });

    removeFromCart(cartItemId) {
        let cart = this.state.cart;
        delete cart[cartItemId];
        localStorage.setItem("cart", JSON.stringify(cart));
        this.setState({ cart });
    }

    addToCart(item) {
        let cart = this.state.cart;
        item.count = 1;
        cart[item._id] = item;
        localStorage.setItem("cart", JSON.stringify(cart));
        this.setState({ cart });
    }

    clearCart() {
        let cart = {};
        localStorage.removeItem("cart");
        this.setState({ cart });
    }

    incrementItemCount(item) {
        let cart = this.state.cart;
        if (cart[item._id]) {
            cart[item._id].count += 1;
        }
        if (cart[item._id].count >= item.stock) {
            cart[item._id].count = cart[item._id].stock - 1;
        }
        localStorage.setItem("cart", JSON.stringify(cart));
        this.setState({ cart });
    }

    decrementItemCount(item) {
        let cart = this.state.cart;
        if (cart[item._id]) {
            cart[item._id].count -= 1;
        }
        if (cart[item._id].count < 1) {
            cart[item._id].count = 1;
        }
        localStorage.setItem("cart", JSON.stringify(cart));
        this.setState({ cart });
    }

    async signup(user) {
        const { data, status } = await api.signUp(user);
        return { status, data };
    }

    async login(email, password) {
        const { data, status } = await api.login(email, password);
        if (status === 200) {
            const { email, name, token, accessLevel } = data;
            const user = { email, name, token, accessLevel };
            this.setState({ user });
            localStorage.setItem("user", JSON.stringify(user));
            return true;
        } else {
            return false;
        }
    }

    async logout(e) {
        e.preventDefault();
        this.setState({ user: null });
        localStorage.removeItem("user");
    }

    async checkout() {
        if (!this.state.user) {
            this.routerRef.current.history.push("/login");
            return;
        }

        const { cart } = this.state;

        const products = Object.keys(cart).map((k) => ({
            item: cart[k]._id,
            count: cart[k].count
        }));
        const { status } = await api.order(
            products,
            true,
            "paytm",
            this.state.user.token
        );
    }

    search() {
        const { searchTerm, allProducts } = this.state;
        this.setState({
            products:
                searchTerm.length >= 1
                    ? allProducts.filter(
                          (e) =>
                              new RegExp(searchTerm, "i").test(e.name) ||
                              new RegExp(searchTerm, "i").test(e.description)
                      )
                    : allProducts
        });
    }

    render() {
        return (
            <Context.Provider
                value={{
                    ...this.state,
                    removeFromCart: this.removeFromCart,
                    addToCart: this.addToCart,
                    login: this.login,
                    logout: this.logout,
                    signup: this.signup,
                    addProduct: this.addProduct,
                    clearCart: this.clearCart,
                    checkout: this.checkout,
                    otpHandler: this.otpHandler,
                    incrementItemCount: this.incrementItemCount,
                    decrementItemCount: this.decrementItemCount,
                    search: this.search,
                    routerRef: this.routerRef,
                    completeSigningUp: this.completeSigningUp,
                    setPinCode: this.setPinCode
                }}
            >
                <Router ref={this.routerRef}>
                    <div className="App">
                        <main>
                            {!this.state.isMobile ? (
                                <>
                                    <Navbar />
                                    <Switch>
                                        <Route
                                            path="/p/:id"
                                            component={Product}
                                        />
                                        <Route
                                            exact
                                            path="/"
                                            component={() => (
                                                <Products
                                                    products={this.state.products
                                                        .filter(
                                                            ({ stock }) =>
                                                                stock > 0
                                                        )
                                                        .sort(
                                                            () =>
                                                                0.5 -
                                                                Math.random()
                                                        )
                                                        .slice(0, 10)}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/cart"
                                            component={Cart}
                                        />
                                        <Route
                                            exact
                                            path="/manage-products"
                                            component={ManageProducts}
                                        />
                                        <Route
                                            exact
                                            path="/products"
                                            component={() => (
                                                <Products
                                                    products={
                                                        this.state.allProducts
                                                    }
                                                />
                                            )}
                                        />
                                        <Route exact component={NotFound} />
                                        <Route
                                            exact
                                            path="/404"
                                            component={NotFound}
                                        />
                                    </Switch>
                                </>
                            ) : (
                                <MobileView />
                            )}
                        </main>
                    </div>
                </Router>
            </Context.Provider>
        );
    }
}
