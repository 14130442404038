import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

export const fetchProducts = () => axios.get(`${BASE_URL}/products`);
export const fetchProduct = (id) =>
    axios.get(`${BASE_URL}/products/${id}`, { validateStatus: false });
export const login = (email, password) =>
    axios.post(
        `${BASE_URL}/auth/login`,
        { email, password },
        { validateStatus: false }
    );
export const signUp = (user) =>
    axios
        .post(`${BASE_URL}/auth/signup`, user, { validateStatus: false })
        .catch(() => ({ status: 401, message: "Unauthorized" }));
export const verifyOtp = (otp, email) =>
    axios.post(
        `${BASE_URL}/auth/otp`,
        {
            email,
            otp: parseInt(otp)
        },
        { validateStatus: false }
    );
export const order = (items, isPaid, paymentMethod, token) =>
    axios.post(
        `${BASE_URL}/orders`,
        { items, isPaid, paymentMethod },
        {
            headers: {
                "x-access-token": token
            }
        }
    );
export const changeDetails = (details, token) =>
    axios.patch(`${BASE_URL}/user`, details, {
        headers: {
            "x-access-token": token
        }
    });
export const completeSigningUp = (p) =>
    axios.patch(`${BASE_URL}/auth/signup`, p, { validateStatus: false });
