import React from "react";
import { Button } from "@material-ui/core";

import withContext from "../../withContext";
import CartItem from "./CartItem/CartItem";

const Cart = (props) => {
    const { cart } = props.context;
    const cartKeys = Object.keys(cart || {});
    return (
        <>
            <br />
            <div className="form-container" style={{ height: "100%" }}>
                <div className="columns is-8 is-variable">
                    <div className="column is-two-thirds">
                        {cartKeys.length ? (
                            <div className="rows">
                                <div className="row">
                                    <h4 className="title">Shopping Cart</h4>
                                    <hr />
                                </div>
                                {cartKeys.map((key) => (
                                    <>
                                        <CartItem
                                            cartKey={key}
                                            key={key}
                                            cartItem={cart[key]}
                                            removeFromCart={
                                                props.context.removeFromCart
                                            }
                                            incrementCount={
                                                props.context.incrementItemCount
                                            }
                                            decrementCount={
                                                props.context.decrementItemCount
                                            }
                                        />
                                        <br />
                                    </>
                                ))}
                            </div>
                        ) : (
                            <div className="column">
                                <div className="title has-text-grey-light">
                                    Your cart is empty
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="column is-one-third is-flex">
                        <div className="is-vcentered">
                            <Button
                                variant={"contained"}
                                className="button is-warning is-fullwidth"
                                onClick={props.context.clearCart}
                            >
                                Clear Cart
                            </Button>
                            <Button
                                variant={"contained"}
                                color="primary"
                                style={{ width: "100%", marginTop: "10px" }}
                                onClick={props.context.checkout}
                            >
                                Checkout
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withContext(Cart);
