import React, { Component } from "react";

export default class ManageProducts extends Component {
    render() {
        return (
            <>
                <div className="hero is-info">
                    <div className="hero-body container">
                        <h4 className="title">Administrator Board</h4>
                    </div>
                </div>
            </>
        );
    }
}
