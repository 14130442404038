import React from "react";

const Mobile = () => {
    return (
        <div className="form-container flex-container">
            <div className="flex-row">
                <div className="box flex-item">
                    <center>
                        <h1 className="title app-title">BookYourMark</h1>
                        <h4>This website doesn't work on mobile yet.</h4>
                    </center>
                </div>
            </div>
        </div>
    );
};

export default Mobile;
