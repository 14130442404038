import React, { Component } from "react";

import withContext from "../../withContext";

import LoginForm from "./LoginForm";
import SignUpForm from "./SignupForm";

import { Modal } from "@material-ui/core";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            loading: false,
            signup: false,
            isOtpSent: false,
            isVerified: false,
            otp: "",
            email: "",
            address: "",
            tel: "",
            name: ""
        };
    }

    handleChange = (e) =>
        this.setState({ [e.target.name]: e.target.value, error: "" });

    login = (e) => {
        e.preventDefault();

        const { username, password } = this.state;
        if (!username || !password) {
            return this.setState({ error: "Fill all fields!", loading: false });
        }
        this.setState({ loading: true });
        this.props.context.login(username, password).then((loggedIn) => {
            if (!loggedIn) {
                return this.setState({
                    error: "Invalid Credentials",
                    loading: false
                });
            }
            this.setState({ loading: false });
            this.props.close();
        });
    };

    signup = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const { email } = this.state;
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!re.test(email))
            return this.setState({ error: "Invalid email format" });
        this.props.context.signup({ email }).then(({ status, data }) => {
            if (status !== 201)
                return this.setState({
                    error: data.message || "An error occurred.",
                    loading: false
                });
            this.setState({ isOtpSent: true, error: null, loading: false });
        });
    };

    completeSignup = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        this.props.context
            .completeSigningUp(
                this.state.name,
                this.state.password,
                this.state.address,
                this.state.tel,
                this.state.email
            )
            .then(({ data, status }) => {
                if (status === 200) {
                    localStorage.setItem("user", JSON.stringify(data));
                    return (location.href = "/");
                }
                this.setState({ error: data.message, loading: false });
            });
    };

    otpHandler = (e) => {
        e.preventDefault();
        const { otp, email } = this.state;
        otp &&
            this.props.context.otpHandler(otp, email).then((isVerified) => {
                if (!isVerified)
                    return this.setState({ error: "Incorrect OTP" });
                console.log("verified");
                this.setState({ isVerified: true });
            });
    };

    setSignup = (v) => this.setState({ signup: v });

    render() {
        return (
            <Modal open={this.props.open} onClose={this.props.close}>
                <div>
                    {!this.state.signup ? (
                        <LoginForm
                            login={this.login}
                            handleChange={this.handleChange}
                            error={this.state.error}
                            setSignup={this.setSignup}
                            close={this.props.close}
                            loading={this.state.loading}
                        />
                    ) : (
                        <SignUpForm
                            handleSubmit={this.signup}
                            handleChange={this.handleChange}
                            error={this.state.error}
                            loading={this.state.loading}
                            otpSent={this.state.isOtpSent}
                            otpHandler={this.otpHandler}
                            isVerified={this.state.isVerified}
                            data={{
                                email: this.state.email,
                                otp: this.state.otp,
                                address: this.state.address,
                                tel: this.state.address,
                                name: this.state.name,
                                password: this.state.password
                            }}
                            handleComplete={this.completeSignup}
                            close={this.props.close}
                        />
                    )}
                </div>
            </Modal>
        );
    }
}

export default withContext(Login);
