import React, { useState } from "react";

// Components
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import Drawer from "@material-ui/core/Drawer";
// import List from "@material-ui/core/List";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import Divider from "@material-ui/core/Divider";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";

import { Link } from "react-router-dom";

// Custom components
import Login from "../Login/Login";

// Icons
import MenuIcon from "@material-ui/icons/Menu";
import CartIcon from "@material-ui/icons/ShoppingCart";
import User from "@material-ui/icons/Person";
import ArrowDropdownIcon from "@material-ui/icons/ArrowDropDown";
import Location from "@material-ui/icons/LocationOn";

// Utils
import useStyles from "./styles";
import withContext from "../../withContext";

const Navbar = ({ context }) => {
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const classes = useStyles();

    const handleProfileMenuOpen = (e) => setAnchorEl(e.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    return (
        <>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        <Link
                            to="/"
                            style={{
                                textDecoration: "none",
                                color: "white"
                            }}
                        >
                            BookYourMark
                        </Link>
                    </Typography>
                    {!context.user ? (
                        <Button
                            className="login-btn"
                            onClick={() => setShowLoginModal(true)}
                        >
                            login
                        </Button>
                    ) : (
                        // <IconButton
                        //     onClick={handleProfileMenuOpen}
                        //     edge="end"
                        //     color="inherit"
                        //     aria-controls="m"
                        // >
                        //     <User />
                        // </IconButton>
                        <Button
                            style={{
                                textTransform: "capitalize",
                                color: "inherit",
                                fontSize: "inherit"
                            }}
                            onClick={handleProfileMenuOpen}
                            variant={"text"}
                        >
                            <User />
                            {context.user.name.split(" ")[0]}
                            <ArrowDropdownIcon />
                        </Button>
                    )}
                    {context.pinCode && (
                        <Button color="inherit">
                            <Location /> {context.pinCode}
                        </Button>
                    )}
                    {/* <IconButton color="inherit">
                        <Link
                            to="/cart"
                            style={{
                                textDecoration: "none",
                                color: "white"
                            }}
                        >
                            <Badge
                                badgeContent={
                                    Object.keys(context?.cart)?.length
                                }
                                color="default"
                            >
                                <CartIcon />
                            </Badge>
                        </Link>
                    </IconButton> */}
                    <Button
                        color="inherit"
                        onClick={() => (location.href = "/cart")}
                    >
                        <CartIcon /> Cart
                    </Button>
                </Toolbar>
            </AppBar>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                id="m"
                getContentAnchorEl={null}
                keepMounted={false}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                open={isMenuOpen}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        transform: "translateX(10px) translateY(50px)"
                    }
                }}
            >
                <MenuItem onClick={handleMenuClose}>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/account"
                        onClick={() => null}
                    >
                        My Account
                    </Link>
                </MenuItem>
                <MenuItem
                    onClick={(e) => {
                        context.logout(e);
                        handleMenuClose();
                    }}
                >
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/"
                    >
                        Logout
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to="/orders"
                    >
                        My Orders
                    </Link>
                </MenuItem>
            </Menu>
            <Login
                open={showLoginModal}
                close={() => setShowLoginModal(false)}
            />
        </>
    );
};

export default withContext(Navbar);
