import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";

import withContext from "../../withContext";
import OTP from "./OTP/OTP";
import { login } from "../../api";

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            address: "",
            tel: "",
            password: "",
            loading: false,
            isOtpSent: false,
            isPasswordSame: true,
            otp: null,
            otpError: null
        };
    }

    /**
     *
     * @param {Event} e
     */
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, error: "" });
    };

    otpChangeHandler = (e) => this.setState({ otp: e.target.value });

    otpHandler = () => {
        const { otp, email } = this.state;
        this.props.context.otpHandler(otp, email).then((isVerified) => {
            if (!isVerified)
                return this.setState({ otpError: "Incorrect OTP" });
            login(email, this.state.password).then(({ data }) => {
                localStorage.setItem("user", JSON.stringify(data));
                location.href = "/";
            });
        });
    };

    signup = (e) => {
        e.preventDefault();

        const { name, email, password, address, tel } = this.state;
        if (!name || !email || !password || !address || !tel) {
            return this.setState({ error: "All fields are required!" });
        }
        this.setState({ loading: true });
        this.state.isPasswordSame
            ? this.props.context
                  .signup({ name, email, password, address, tel })
                  .then(({ status, data }) => {
                      if (status !== 201)
                          return this.setState({
                              error: data.message || "An error occurred.",
                              loading: false
                          });
                      this.setState({ isOtpSent: true, loading: false });
                      //   localStorage.setItem(
                      //       "user",
                      //       JSON.stringify({ email, isVerified: false })
                      //   );
                  })
            : this.setState({ error: "Passwords do not match." });
    };

    isPasswordSame = (e) =>
        this.setState({
            isPasswordSame: this.state.password === e.target.value
        });

    checkIsPhoneValid = (e) => {
        const tel = e.target.value;
        if (/[789][0-9]{9}/.test(tel))
            return this.setState({ tel, telError: null });
        this.setState({ telError: "Invalid Contact Number" });
    };

    render() {
        return !this.props.context.user ? (
            <>
                <div className="hero is-small">
                    <div className="hero-body container">
                        <h4 className="title">Sign Up</h4>
                    </div>
                </div>
                <br />
                <br />
                {!this.state.isOtpSent ? (
                    <form onSubmit={this.signup}>
                        <div className="is-mobile is-centered login-container">
                            <div>
                                <div className="field">
                                    <label className="label">Your name</label>
                                    <p className="control has-icons-left">
                                        <input
                                            className="input"
                                            type="text"
                                            name="name"
                                            required
                                            onChange={this.handleChange}
                                            autoFocus
                                        />
                                        <span className="icon is-small is-left">
                                            <FontAwesomeIcon icon={faUser} />
                                        </span>
                                    </p>
                                </div>
                                <div className="field">
                                    <label className="label">Email</label>
                                    <p className="control has-icons-left">
                                        <input
                                            className="input"
                                            required
                                            type="email"
                                            name="email"
                                            pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                                            onChange={this.handleChange}
                                        />
                                        <span className="icon is-small is-left">
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                            />
                                        </span>
                                    </p>
                                </div>
                                <div className="field has-addons">
                                    <label className="label">
                                        Contact Number
                                    </label>
                                    <div className="control">
                                        <span className="button is-static">
                                            <p>+91</p>
                                        </span>
                                    </div>
                                    <p className="control">
                                        <input
                                            className={`input${
                                                this.state.telError
                                                    ? " is-danger"
                                                    : ""
                                            }`}
                                            required
                                            type="tel"
                                            name="tel"
                                            pattern="[789][0-9]{9}"
                                            onChange={this.checkIsPhoneValid}
                                            maxLength={10}
                                        />
                                    </p>
                                </div>
                                <div className="field">
                                    <label className="label">Password</label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            type="password"
                                            name="password"
                                            onChange={this.handleChange}
                                            required
                                            minLength={8}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">
                                        Confirm password
                                    </label>
                                    <div className="control">
                                        <input
                                            className={`input${
                                                this.state.isPasswordSame
                                                    ? ""
                                                    : " is-danger"
                                            }`}
                                            type="password"
                                            name="confirmPassword"
                                            onChange={this.isPasswordSame}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="field">
                                    <label className="label">Address</label>
                                    <textarea
                                        rows="4"
                                        required
                                        className="textarea"
                                        name="address"
                                        minLength={50}
                                        maxLength={150}
                                        onChange={this.handleChange}
                                    />
                                </div>

                                {this.state.error && (
                                    <div className="has-text-danger">
                                        {this.state.error}
                                    </div>
                                )}
                                <div className="field is-clearfix">
                                    {!this.state.loading ? (
                                        <button className="button is-info is-outlined is-fullwidth">
                                            Sign Up
                                        </button>
                                    ) : (
                                        <CircularProgress />
                                    )}
                                </div>
                                <br />
                            </div>
                        </div>
                    </form>
                ) : (
                    <OTP
                        handler={this.otpHandler}
                        onChange={this.otpChangeHandler}
                        error={this.state.otpError}
                    />
                )}
            </>
        ) : (
            <Redirect to="/products" />
        );
    }
}

export default withContext(Signup);
