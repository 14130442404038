import React from "react";
import {
    Button,
    TextField,
    InputAdornment,
    IconButton,
    LinearProgress
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { Link } from "react-router-dom";

/**
 * @param {{ login: (e: Event) => void, handleChange: (e: Event) => void, error: string }} param0
 * @returns {JSX.Element}
 */
const LoginForm = ({
    login,
    handleChange,
    error,
    setSignup,
    close,
    loading
}) => {
    return (
        <div
            className="form-container columns is-flex is-centered"
            style={{
                padding: 0,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                boxShadow: 24
            }}
        >
            <div
                className="columns is-flex box mt-5 is-0"
                style={{
                    height: "calc(100vh - 110px)",
                    minHeight: "510px",
                    padding: 0,
                    minWidth: "650px",
                    maxWidth: "750px",
                    width: "100%",
                    borderRadius: 0
                }}
            >
                <div
                    className="column has-text-white is-two-fifths"
                    style={{
                        backgroundSize: "100% 100%",
                        height: "100%",
                        backgroundColor: "#2882f0",
                        padding: "30px"
                    }}
                >
                    <h1 className="is-size-4" style={{ fontWeight: "600" }}>
                        Login
                    </h1>
                    <h4 className="is-size-5 has-text-grey-light mt-1">
                        To get access to orders and much more
                    </h4>
                </div>
                <div
                    className="column is-three-fifths is-relative"
                    style={{ padding: "56px 35px 56px 35px" }}
                >
                    <form onSubmit={login} className="is-fullwidth">
                        <div>
                            <TextField
                                fullWidth
                                variant="standard"
                                type="email"
                                name="username"
                                label="Enter Email"
                                onChange={handleChange}
                                autoFocus
                                color={"primary"}
                                size="medium"
                                InputLabelProps={{
                                    style: {
                                        color: "rgba(0, 0, 0, 0.5)"
                                    }
                                }}
                                InputProps={{
                                    style: {
                                        background: "none",
                                        boxShadow: "none"
                                    }
                                }}
                                error={!!error}
                                helperText={error}
                            />

                            <TextField
                                fullWidth
                                variant="standard"
                                type="password"
                                name="password"
                                label="Enter Password"
                                onChange={handleChange}
                                color={"primary"}
                                className="mt-5"
                                size="medium"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Link
                                                to="/account/password/reset"
                                                className="is-link is-size-6"
                                            >
                                                Forgot?
                                            </Link>
                                        </InputAdornment>
                                    ),
                                    style: {
                                        background: "none",
                                        boxShadow: "none"
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: "rgba(0, 0, 0, 0.5)"
                                    }
                                }}
                            />

                            <div className="mt-5">
                                <Button
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    style={{
                                        height: "48px",
                                        borderRadius: "0"
                                    }}
                                >
                                    Login
                                </Button>
                            </div>
                        </div>
                        {loading && (
                            <div
                                className="is-overlay"
                                style={{
                                    position: "absolute",
                                    top: "0",
                                    width: "100%",
                                    left: 0,
                                    background: "rgb(211, 211, 211, 0.3)",
                                    transition: "1s filter linear"
                                }}
                            >
                                <LinearProgress />
                            </div>
                        )}

                        <div
                            className="mt-5"
                            style={{
                                position: "absolute",
                                bottom: "30px",
                                textAlign: "center",
                                left: "50%",
                                transform: "translateX(-50%)"
                            }}
                        >
                            <Link
                                to="#"
                                className="is-link"
                                style={{
                                    marginBottom: 0,
                                    bottom: 0,
                                    fontWeight: "500"
                                }}
                                onClick={() => setSignup(true)}
                            >
                                New Customer? Sign Up
                            </Link>
                        </div>
                        <div
                            style={{
                                position: "absolute",
                                top: "0",
                                width: "100%",
                                left: 0,
                                padding: "5px"
                            }}
                        >
                            <IconButton
                                style={{ float: "right" }}
                                onClick={close}
                            >
                                <Close />
                            </IconButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;
