import React from "react";
import {
    Button,
    TextField,
    LinearProgress,
    IconButton
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";

/**
 * @param {{ handleSubmit: (e: Event) => void, handleChange: (e: Event) => void, error: string, loading: boolean, otpSent: boolean, otpHandler: (otp: number, email: string) => void, isVerified: boolean, data: { email: string, otp: string, address: string, tel: string, name: string, password: string }, handleComplete: (e: Event) => void }} param0
 * @returns {JSX.Element}
 */
const SignUpForm = ({
    handleSubmit,
    handleChange,
    error,
    loading,
    otpSent,
    otpHandler,
    isVerified,
    data,
    handleComplete,
    close
}) => {
    return (
        <>
            <div
                className="form-container columns is-flex is-centered"
                style={{
                    padding: 0,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    boxShadow: 24
                }}
            >
                <div
                    className="columns is-flex box mt-5 is-0"
                    style={{
                        height: "calc(100vh - 110px)",
                        minHeight: "510px",
                        padding: 0,
                        minWidth: "650px",
                        maxWidth: "750px",
                        width: "100%",
                        borderRadius: 0
                    }}
                >
                    <div
                        className="column has-text-white is-two-fifths"
                        style={{
                            backgroundSize: "100% 100%",
                            height: "100%",
                            backgroundColor: "#2882f0",
                            padding: "30px"
                        }}
                    >
                        <h1 className="is-size-4" style={{ fontWeight: "600" }}>
                            New Here?
                        </h1>
                        <h4 className="is-size-5 has-text-grey-light mt-1">
                            Lets get you signed up!
                        </h4>
                    </div>
                    <div
                        className="column is-three-fifths is-relative"
                        style={{ padding: "56px 35px 56px 35px" }}
                    >
                        {!otpSent ? (
                            <form
                                onSubmit={handleSubmit}
                                className="is-fullwidth"
                            >
                                <div>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        type="email"
                                        name="email"
                                        label="Enter Your Email"
                                        color={"primary"}
                                        size="medium"
                                        required
                                        InputLabelProps={{
                                            style: {
                                                color: "rgba(0, 0, 0, 0.5)"
                                            }
                                        }}
                                        inputProps={{
                                            style: {
                                                background: "none",
                                                boxShadow: "none"
                                            }
                                        }}
                                        onChange={handleChange}
                                        error={!!error}
                                        helperText={error}
                                        value={data.email}
                                    />

                                    <div className="mt-5">
                                        <Button
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={loading}
                                            style={{
                                                height: "48px",
                                                borderRadius: "0"
                                            }}
                                        >
                                            Continue
                                        </Button>
                                    </div>

                                    {loading && (
                                        <div
                                            className="is-overlay"
                                            style={{
                                                position: "absolute",
                                                top: "0",
                                                width: "100%",
                                                left: 0,
                                                background:
                                                    "rgb(211, 211, 211, 0.3)",
                                                transition: "1s filter linear"
                                            }}
                                        >
                                            <LinearProgress />
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "0",
                                            width: "100%",
                                            left: 0,
                                            padding: "5px"
                                        }}
                                    >
                                        <IconButton
                                            style={{ float: "right" }}
                                            onClick={close}
                                        >
                                            <Close />
                                        </IconButton>
                                    </div>
                                </div>
                            </form>
                        ) : (
                            <>
                                {!isVerified ? (
                                    <form
                                        onSubmit={otpHandler}
                                        className="is-fullwidth"
                                    >
                                        <div>
                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                type="text"
                                                name="otp"
                                                label="Enter the OTP"
                                                color={"primary"}
                                                size="medium"
                                                InputLabelProps={{
                                                    style: {
                                                        color: "rgba(0, 0, 0, 0.5)"
                                                    }
                                                }}
                                                inputProps={{
                                                    style: {
                                                        background: "none",
                                                        boxShadow: "none"
                                                    },
                                                    pattern: "[1-9][0-9]{5}",
                                                    maxLength: "6",
                                                    minLength: "6"
                                                }}
                                                onChange={handleChange}
                                                error={!!error}
                                                helperText={error}
                                                required
                                                value={data.otp}
                                            />

                                            <div className="mt-5">
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={loading}
                                                    style={{
                                                        height: "48px",
                                                        borderRadius: "0"
                                                    }}
                                                >
                                                    Verify
                                                </Button>
                                            </div>

                                            {loading && (
                                                <div
                                                    className="is-overlay"
                                                    style={{
                                                        position: "absolute",
                                                        top: "0",
                                                        width: "100%",
                                                        left: 0,
                                                        background:
                                                            "rgb(211, 211, 211, 0.3)",
                                                        transition:
                                                            "1s filter linear"
                                                    }}
                                                >
                                                    <LinearProgress />
                                                </div>
                                            )}
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "0",
                                                    width: "100%",
                                                    left: 0,
                                                    padding: "5px"
                                                }}
                                            >
                                                <IconButton
                                                    style={{ float: "right" }}
                                                    onclick={close}
                                                >
                                                    <Close />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </form>
                                ) : (
                                    <form onSubmit={handleComplete}>
                                        <div>
                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                type="text"
                                                name="name"
                                                label="Full name"
                                                color={"primary"}
                                                size="medium"
                                                InputLabelProps={{
                                                    style: {
                                                        color: "rgba(0, 0, 0, 0.5)"
                                                    }
                                                }}
                                                inputProps={{
                                                    style: {
                                                        background: "none",
                                                        boxShadow: "none"
                                                    }
                                                }}
                                                onChange={handleChange}
                                                error={!!error}
                                                helperText={error}
                                                required
                                                value={data.name}
                                            />

                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                type={"tel"}
                                                name="tel"
                                                label="Contact number"
                                                color={"primary"}
                                                size="medium"
                                                InputLabelProps={{
                                                    style: {
                                                        color: "rgba(0, 0, 0, 0.5)"
                                                    }
                                                }}
                                                inputProps={{
                                                    style: {
                                                        background: "none",
                                                        boxShadow: "none"
                                                    },
                                                    pattern: "[789][0-9]{9}"
                                                }}
                                                onChange={handleChange}
                                                error={!!error}
                                                helperText={error}
                                                required
                                                // value={data.tel}
                                                className="mt-3"
                                            />

                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                type="password"
                                                name="password"
                                                label="Password"
                                                color={"primary"}
                                                size="medium"
                                                InputLabelProps={{
                                                    style: {
                                                        color: "rgba(0, 0, 0, 0.5)"
                                                    }
                                                }}
                                                inputProps={{
                                                    style: {
                                                        background: "none",
                                                        boxShadow: "none"
                                                    }
                                                }}
                                                onChange={handleChange}
                                                error={!!error}
                                                helperText={error}
                                                required
                                                value={data.password}
                                                className="mt-3"
                                            />

                                            <TextField
                                                fullWidth
                                                variant="standard"
                                                type="text"
                                                name="address"
                                                label="Billing address"
                                                color={"primary"}
                                                size="medium"
                                                InputLabelProps={{
                                                    style: {
                                                        color: "rgba(0, 0, 0, 0.5)"
                                                    }
                                                }}
                                                inputProps={{
                                                    style: {
                                                        background: "none",
                                                        boxShadow: "none"
                                                    }
                                                }}
                                                onChange={handleChange}
                                                error={!!error}
                                                helperText={error}
                                                required
                                                value={data.address}
                                                className="mt-3"
                                            />

                                            <div className="mt-5">
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={loading}
                                                    style={{
                                                        height: "48px",
                                                        borderRadius: "0"
                                                    }}
                                                >
                                                    continue
                                                </Button>
                                            </div>

                                            {loading && (
                                                <div
                                                    className="is-overlay"
                                                    style={{
                                                        position: "absolute",
                                                        top: "0",
                                                        width: "100%",
                                                        left: 0,
                                                        background:
                                                            "rgb(211, 211, 211, 0.3)",
                                                        transition:
                                                            "1s filter linear"
                                                    }}
                                                >
                                                    <LinearProgress />
                                                </div>
                                            )}
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "0",
                                                    width: "100%",
                                                    left: 0,
                                                    padding: "5px"
                                                }}
                                            >
                                                <IconButton
                                                    style={{ float: "right" }}
                                                    onClick={close}
                                                >
                                                    <Close />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignUpForm;
