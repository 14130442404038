import React from "react";

const OTP = (props) => {
    return (
        <div>
            <div className="form-container">
                <div className="field">
                    <label className="label">Enter the OTP</label>
                    <input
                        className="input"
                        required
                        type="text"
                        maxLength="6"
                        minLength="6"
                        name="email"
                        onChange={props.onChange}
                    />
                </div>

                {props.error && (
                    <div className="has-text-danger">{props.error}</div>
                )}
                <div className="field is-clearfix">
                    <button
                        className="button is-info is-outlined is-pulled-right"
                        onClick={props.handler}
                    >
                        Verify OTP
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OTP;
