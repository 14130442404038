import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

/**
 * @typedef {object} Product
 * @property {string} _id
 * @property {string} name
 * @property {string} description
 * @property {string} image
 * @property {number} cost
 * @property {number} stock
 * @property {boolean} isLightningDeal
 * @property {any[]} reviews
 */

/**
 * @typedef {object} ProductProps
 * @property {Product} product
 * @property {boolean} isInCart
 * @property {(product: Product) => void} addToCart
 */

/**
 *
 * @param {ProductProps} props
 */
const Product = (props) => {
    const { product } = props;
    return (
        <div className="column is-half">
            <div className="box">
                <div className="media">
                    <div className="media-left">
                        <figure className="image is-64x64">
                            <img
                                src={
                                    product.image ||
                                    "https://bulma.io/images/placeholders/128x128.png"
                                }
                                alt=""
                            />
                        </figure>
                    </div>
                    <div className="media-content">
                        <b style={{ textTransform: "capitalize" }}>
                            <Link
                                to={`/p/${product._id}`}
                                target="_blank"
                                className="is-link"
                                style={{ textDecoration: "underline" }}
                            >
                                {product.name}{" "}
                                <FontAwesomeIcon
                                    icon={faExternalLinkAlt}
                                    height="12"
                                    width="12"
                                />
                            </Link>
                        </b>
                        <div
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden"
                            }}
                        >
                            {product.description}
                        </div>
                        {product.stock > 0 ? (
                            <small>{"Available"}</small>
                        ) : (
                            <small className="has-text-danger">
                                Currently Unavailable
                            </small>
                        )}
                        {product.stock > 0 && (
                            <span
                                className="tag is-info is-pulled-right"
                                style={{ bottom: 0 }}
                            >
                                ₹{" "}
                                {product.isLightningDeal
                                    ? `${product.cost} 🔥`
                                    : product.cost}
                            </span>
                        )}
                        {/* <div className="is-clearfix">
                            <button
                                className="button is-small is-pulled-right"
                                onClick={() => props.addToCart(product)}
                                style={{
                                    visibility:
                                        product.stock < 1
                                            ? "hidden"
                                            : "visible",
                                    borderBottomLeftRadius: "7px",
                                    borderBottomRightRadius: "7px",
                                    borderTopLeftRadius: "7px",
                                    borderTopRightRadius: "7px",
                                    backgroundColor: "rgb(252, 210, 0)",
                                    boxShadow: "none"
                                }}
                                disabled={props.isInCart}
                            >
                                Add to Cart
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;
