import React from "react";

import { Box } from "@material-ui/core";

import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <Box>
            <div className="center-all">
                <center>
                    <h1 className="is-size-1">
                        <b>404 - Not Found</b>
                    </h1>
                    <h3 className="is-size-3">
                        We couldn't found the page you requested
                    </h3>
                    <br />
                    <h4 className="is-size-4">
                        Perhaps go back to <Link to="/">home</Link>
                    </h4>
                </center>
            </div>
        </Box>
    );
};

export default NotFound;
