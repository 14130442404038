import React from "react";
import { CircularProgress } from "@material-ui/core";

import Product from "./Product/Product";
import withContext from "../../withContext";

const Products = (props) => {
    const { products } = props;
    const cart = Object.keys(JSON.parse(localStorage.getItem("cart")) || {});
    return (
        <>
            <br />
            <div className="container">
                <div className="column columns is-multiline">
                    {products && products.length ? (
                        products.map((product) => (
                            <Product
                                product={product}
                                key={product._id}
                                addToCart={props.context.addToCart}
                                removeFromCart={props.context.removeFromCart}
                                isInCart={cart.includes(product._id)}
                            />
                        ))
                    ) : (
                        <div className="center-all">
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default withContext(Products);
