import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStar } from "@fortawesome/free-solid-svg-icons";

import { fetchProduct } from "../../api";

class Product extends Component {
    constructor(props) {
        super(props);
        /**
         * @type {{ product: import('../Products/Product/Product').Product }}
         */
        this.state = {
            product: null,
            width: window.innerWidth,
            isInCart: false
        };
    }

    setWidth = () => this.setState({ width: window.innerWidth });

    addToCart = (i) => {
        const cart = localStorage.getItem("cart")
            ? JSON.parse(localStorage.getItem("cart"))
            : {};
        if (cart[i._id]) return;
        cart[i._id] = i;
        cart[i._id].count = 1;
        localStorage.setItem("cart", JSON.stringify(cart));
    };

    async componentDidMount() {
        const { id } = this.props.match.params;

        const { data: product, status } = await fetchProduct(id);

        if (status === 404) return (location.href = "/404");

        this.setState({ product });

        this.setState({ isMobile: this.state.width <= 768 });

        document.title = `${this.state.product.name} - BookYourMark`;

        window.addEventListener("resize", () => {
            this.setWidth();
            this.setState({ isMobile: this.state.width <= 768 });
        });

        const productExists = JSON.parse(localStorage.getItem("cart") || "{}")[
            this.state.product._id
        ];

        this.setState({ isInCart: productExists });

        return () => {
            window.removeEventListener("resize", () => {
                this.setWidth();
                this.setState({ isMobile: this.state.width <= 768 });
            });
        };
    }

    render() {
        return !this.state.product ? (
            <center>
                <CircularProgress />
            </center>
        ) : (
            <>
                {this.state.isMobile ? (
                    <div
                        className="form-container rows is-mobile box"
                        style={{
                            wordWrap: "break-word"
                        }}
                    >
                        <div className="row">
                            <p>{this.state.product.name}</p>
                        </div>
                        <div className="row">
                            <figure className="image is-pulled-left is-fullwidth">
                                <img
                                    alt={this.state.product.description}
                                    src={this.state.product.image}
                                />
                            </figure>
                        </div>
                        <div className="row">
                            <div className="rows">
                                <div className="row">
                                    <p className="subtitle">
                                        {this.state.product.description}
                                    </p>
                                </div>
                                <hr />
                                <div className="row">
                                    <b className="is-size-6">Total: </b>
                                    <b className="is-size-6">
                                        ₹{this.state.product.cost}
                                    </b>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <button
                                className="button is-pulled-right is-fullwidth"
                                style={{
                                    borderBottomLeftRadius: "7px",
                                    borderBottomRightRadius: "7px",
                                    borderTopLeftRadius: "7px",
                                    borderTopRightRadius: "7px",
                                    backgroundColor: "rgb(250, 137, 0)",
                                    boxShadow: "none"
                                }}
                                disabled={this.state.product.stock < 1}
                            >
                                Buy Now
                            </button>
                            <br />
                            <br />
                            <button
                                className="button is-pulled-right is-fullwidth"
                                onClick={() =>
                                    this.addToCart(this.state.product)
                                }
                                style={{
                                    visibility:
                                        this.state.product.stock < 1
                                            ? "hidden"
                                            : "visible",
                                    borderBottomLeftRadius: "7px",
                                    borderBottomRightRadius: "7px",
                                    borderTopLeftRadius: "7px",
                                    borderTopRightRadius: "7px",
                                    backgroundColor: "rgb(252, 210, 0)",
                                    boxShadow: "none"
                                }}
                                disabled={this.state.isInCart}
                            >
                                Add to Cart
                            </button>
                            <br />
                        </div>
                        <br />
                    </div>
                ) : (
                    <>
                        <br />
                        <div className="rows">
                            <div className="row">
                                <div
                                    className="form-container columns box"
                                    style={{ wordWrap: "break-word" }}
                                >
                                    <div className="column">
                                        <figure className="image is-pulled-left is-fullwidth">
                                            <img
                                                alt=""
                                                src={this.state.product.image}
                                            />
                                        </figure>
                                    </div>
                                    <div className="column">
                                        <div className="rows">
                                            <div className="row">
                                                <b style={{ fontSize: "24px" }}>
                                                    {this.state.product.name}
                                                </b>
                                                <hr />
                                            </div>
                                            <div className="row">
                                                <p className="subtitle">
                                                    {
                                                        this.state.product
                                                            .description
                                                    }
                                                </p>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="columns">
                                                    <div className="column">
                                                        <p
                                                            className="is-size-2"
                                                            style={{
                                                                color: "#B12704"
                                                            }}
                                                        >
                                                            <sup>₹</sup>
                                                            {
                                                                this.state
                                                                    .product
                                                                    .cost
                                                            }
                                                        </p>
                                                        <p className="has-text-grey">
                                                            Inclusive of all
                                                            taxes
                                                        </p>
                                                    </div>
                                                    <div className="column">
                                                        <button
                                                            className="button is-pulled-right is-fullwidth"
                                                            style={{
                                                                borderBottomLeftRadius:
                                                                    "7px",
                                                                borderBottomRightRadius:
                                                                    "7px",
                                                                borderTopLeftRadius:
                                                                    "7px",
                                                                borderTopRightRadius:
                                                                    "7px",
                                                                backgroundColor:
                                                                    "rgb(250, 137, 0)",
                                                                boxShadow:
                                                                    "none"
                                                            }}
                                                            disabled={
                                                                this.state
                                                                    .product
                                                                    .stock < 1
                                                            }
                                                        >
                                                            Buy Now
                                                        </button>
                                                        <br />
                                                        <br />
                                                        <button
                                                            className="button is-pulled-right is-fullwidth"
                                                            onClick={() =>
                                                                this.addToCart(
                                                                    this.state
                                                                        .product
                                                                )
                                                            }
                                                            style={{
                                                                visibility:
                                                                    this.state
                                                                        .product
                                                                        .stock <
                                                                    1
                                                                        ? "hidden"
                                                                        : "visible",
                                                                borderBottomLeftRadius:
                                                                    "7px",
                                                                borderBottomRightRadius:
                                                                    "7px",
                                                                borderTopLeftRadius:
                                                                    "7px",
                                                                borderTopRightRadius:
                                                                    "7px",
                                                                backgroundColor:
                                                                    "rgb(252, 210, 0)",
                                                                boxShadow:
                                                                    "none"
                                                            }}
                                                            disabled={
                                                                this.state
                                                                    .isInCart
                                                            }
                                                        >
                                                            Add to Cart
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="columns">
                                    <div className="column ml-2">
                                        <div className="form-container">
                                            <h4 className="title is-size-4">
                                                Customer reviews
                                            </h4>
                                            <p className="is-size-6">
                                                {
                                                    this.state.product.reviews.map(
                                                        ({ rating }) => rating
                                                    ).length
                                                }{" "}
                                                ratings
                                            </p>
                                            {/* <div>
                                            {[...Array(5)].map((x, i) => (
                                                <FontAwesomeIcon
                                                    // color={this.state.product.}
                                                    key={i}
                                                    icon={faStar}
                                                />
                                            ))}
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="column">test</div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }
}

export default withRouter(Product);
