import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const CartItem = (props) => {
    const { cartItem, cartKey } = props;

    return (
        <div className="row">
            <div className="box">
                <div className="media">
                    <div className="media-left">
                        <figure className="image is-64x64">
                            <img
                                src={cartItem.image}
                                alt={cartItem.description}
                            />
                        </figure>
                    </div>
                    <div className="media-content">
                        <b style={{ textTransform: "capitalize" }}>
                            <Link
                                to={`/p/${cartItem._id}`}
                                className="is-link"
                                style={{ textDecoration: "underline" }}
                            >
                                {cartItem.name}{" "}
                                <FontAwesomeIcon
                                    icon={faExternalLinkAlt}
                                    height="12"
                                    width="12"
                                />
                            </Link>
                            {cartItem.stock > 0 && (
                                <span className="tag is-info is-pulled-right is-hidden-mobile">
                                    ₹{" "}
                                    {cartItem.isLightningDeal
                                        ? `${cartItem.cost} 🔥`
                                        : cartItem.cost}
                                </span>
                            )}
                        </b>
                        <div
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden"
                            }}
                        >
                            {cartItem.description}
                        </div>
                        <small>
                            <div className="field has-addons">
                                <p className="control">
                                    <button
                                        className="button is-small"
                                        onClick={() =>
                                            props.decrementCount(cartItem)
                                        }
                                    >
                                        -
                                    </button>
                                </p>
                                <p className="control">
                                    <input
                                        value={cartItem.count}
                                        readOnly
                                        type="text"
                                        className="input is-small"
                                        style={{
                                            textAlign: "center",
                                            maxWidth: "35px"
                                        }}
                                    />
                                </p>
                                <p className="control">
                                    <button
                                        className="button is-small"
                                        onClick={() =>
                                            props.incrementCount(cartItem)
                                        }
                                    >
                                        +
                                    </button>
                                </p>
                            </div>
                        </small>
                    </div>
                    <div
                        className="media-right"
                        onClick={() => props.removeFromCart(cartKey)}
                    >
                        <span className="delete is-large"></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartItem;
